@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1637660098690/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1637660098690/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1637660098690/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-arrow-left:before { content: "\EA01" }
.icon-arrow-right:before { content: "\EA02" }
.icon-bearbeiten:before { content: "\EA03" }
.icon-check:before { content: "\EA04" }
.icon-close:before { content: "\EA05" }
.icon-download:before { content: "\EA06" }
.icon-dropdown:before { content: "\EA07" }
.icon-elements:before { content: "\EA08" }
.icon-facebook:before { content: "\EA09" }
.icon-faq:before { content: "\EA0A" }
.icon-kalender:before { content: "\EA0B" }
.icon-kontakt:before { content: "\EA0C" }
.icon-language:before { content: "\EA0D" }
.icon-login:before { content: "\EA0E" }
.icon-mail:before { content: "\EA0F" }
.icon-menu:before { content: "\EA10" }
.icon-neu:before { content: "\EA11" }
.icon-pinterest:before { content: "\EA12" }
.icon-play:before { content: "\EA13" }
.icon-plus:before { content: "\EA14" }
.icon-registrierung:before { content: "\EA15" }


:root {
--icon-arrow-left: "\EA01";
    --icon-arrow-right: "\EA02";
    --icon-bearbeiten: "\EA03";
    --icon-check: "\EA04";
    --icon-close: "\EA05";
    --icon-download: "\EA06";
    --icon-dropdown: "\EA07";
    --icon-elements: "\EA08";
    --icon-facebook: "\EA09";
    --icon-faq: "\EA0A";
    --icon-kalender: "\EA0B";
    --icon-kontakt: "\EA0C";
    --icon-language: "\EA0D";
    --icon-login: "\EA0E";
    --icon-mail: "\EA0F";
    --icon-menu: "\EA10";
    --icon-neu: "\EA11";
    --icon-pinterest: "\EA12";
    --icon-play: "\EA13";
    --icon-plus: "\EA14";
    --icon-registrierung: "\EA15";
    
}