.custom-radio{
    position: relative;
    padding-left: 1.875em;
}

.custom-radio:before,
.custom-radio:after {
    content: " ";
    display: table;
}

.custom-radio:after {
     clear: both;
}

.custom-radio>label{
    cursor: pointer;
}

.custom-radio__input {
    position: absolute;
    width: calc(.875rem/16);
    height: calc(.875rem/16);
    margin: calc(-.875rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-radio__box {
    position: absolute;
    left: 0;
    top:.25rem;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: 0;
    background: linear-gradient(0deg, #FFFFFF 0%, #E0E0E0 100%);
    box-shadow: inset 4px 4px 7px 0 #DCDBDB, inset -4px -4px 7px 0 #FFFFFF, 4px 4px 7px 0 #DBDBDB, -4px -4px 7px 0 #FFFFFF;
    width: 1.25em;
    height: 1.25em;
    border-radius: 50%;
}
.custom-radio__box:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 37.5%;
    height: 37.5%;
    border-radius: 50%;
    background: var(--color-primary);
    visibility: hidden;
}
.custom-radio__input:checked ~ .custom-radio__box:before {
    visibility: visible;
}
.custom-radio__input:focus ~ .custom-radio__box {
    border-color: var(--color-primary);
}
.has-error .custom-radio__box {
    border-color: var(--color-danger)
}
.custom-radio__text {
    display: block;
    overflow: hidden;
    font-size:.875rem;
    /*color: var(--color-text-muted);*/
}
.custom-radio__text a {
    color:var(--color-text-default);
    text-decoration: underline;
}
.custom-radio__text a:hover,
.custom-radio__text a:focus {
    color:var(--color-primary);
}

.custom-radio__img {
    margin:0 .25rem 0 .5rem;
}



/* Large Radios */

.radio-group--lg .custom-radio{
    padding-left: 2.25em;
}

.payment-item .custom-radio__text {
    font-size:1rem;
    color: var(--color-text-default);
}

.radio-group--lg .custom-radio__text {
    font-size:1rem;
    color: var(--color-text-default);

    @media screen and (min-width: 768px){
        font-size:1.25rem;
    }
}

/* inline */
.form-check-inline {
    margin:0 1.5rem;
}
.form-check-inline .form-check-input {
    position: absolute; /* override bootstrap */
}