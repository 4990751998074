.order-box {
    font-size:calc(15rem/16);
    position: relative;
    padding:1.25rem 0 0 0;
    border-radius:1rem;
    overflow: hidden;
    margin:1rem 0 2.5rem 0;
    background: linear-gradient(0deg, #FFFFFF 0%, #E0E0E0 100%);
    box-shadow: 0 5px 10px 0 rgba(144,152,158,0.33);

    @media screen and (min-width: 768px) {
        padding:1.75rem 0 0 0;
    }
}
.order-box.order-box--payment {
    padding:1.75rem 0;
}
.order-box.order-box--detail {
    margin: 1rem 0;
}
.order-box__lbl {
    font-size:calc(12rem/16);
}
.order-box__edit {
    font-size:calc(14rem/16);
}
.order-box__bus {
    font-weight:700;
    font-size: calc(18rem/16);
    white-space:nowrap;
    @media screen and (min-width: 768px) {
        font-size: calc(24rem/16);
    }
}

.order-box__totalprice {
    font-size: calc(30rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(48rem/16);
    }

}


/* order details */
.order-box-item {
    margin:0 0 1.25rem 0;
    padding:0 1.25rem 1.25rem 1.25rem;
    border-bottom:1px solid #dadada;

    @media screen and (min-width: 768px) {
        margin:0 0 1.75rem 0;
        padding:0 1.5rem 1.75rem 1.5rem;

    }
}
.order-box-item.order-box-item--total {
    margin:-.5rem 0 .5rem 0;
    border:0;

    @media screen and (min-width: 768px) {
        /*margin-top:-1rem;*/
    }
}
.order-box-item.order-box-item--edit:last-of-type,
.order-box-item.order-box-item--success:last-of-type {
    margin:0;
    border:0;
}
.order-box__btn {
    @media screen and (max-width: 767px) {
        margin-top:.5rem;
    }

    @media screen and (min-width: 768px) {
        /*position: relative;
        top:-.5rem;
        margin-bottom: -1rem;*/
        padding:1rem 1.125rem;
    }
}



/* payment */
.payment-hl {
    padding:0 1rem;
    @media screen and (min-width: 768px) {
        padding: 0 1rem 0 1.5rem;
    }
}
.payment-item {
    margin:0;
    padding:1rem 1rem .125rem 1rem;
    border-bottom:1px solid #dadada;

    @media screen and (min-width: 768px) {
        padding: 1.25rem 1rem .625rem 1.5rem;
    }
}
.payment-item.payment-item--no-border {
    border:0;
}