.content-block {
    margin-bottom: calc(50rem/16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(100rem/16);
    }

    @media screen and (min-width: 1440px) {
        margin-bottom: calc(120rem/16);
    }
}