.header {
    position: absolute;
    top:0;
    left:0;
    right:0;
    height:120px;
    border-top:70px solid #fff;
    z-index:99;
    background:transparent;
}

/* mobile */
@media screen and (max-width: 991px){
    .header {
        height:60px;
        border:0;
        background: #fff;
    }
}