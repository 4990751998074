.welcome-teaser {
    border-radius:1rem;
    background: linear-gradient(0deg, #FFFFFF 0%, #E0E0E0 100%);
    box-shadow: 0 5px 10px 0 rgba(144,152,158,0.33);
}
.welcome-teaser:hover,
.welcome-teaser:focus {
    box-shadow: 0 5px 10px 0 rgba(144,152,158,0.5);
}
.welcome-teaser__body {
    padding:2rem;
    text-align: center;
    position: absolute;
    top:50%;
    left:0;
    right:0;
    transform: translateY(-50%);
}
.welcome-teaser__icon {
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    left:1rem;
    color:#fff;
    font-size:4.5rem;

    @media screen and (min-width: 768px) {
        left:2rem;
        font-size:8.5rem;
    }
}
.welcome-teaser__line1 {
    font-size:calc(15rem/16);
    letter-spacing: 1px;
    line-height:1;
    margin-bottom:.5rem;

    @media screen and (min-width: 768px) {
        letter-spacing: 2px;
    }
}
.welcome-teaser__line2 {
    font-size:calc(20rem/16);
    font-weight: 700;
    letter-spacing: 2px;
    line-height:1;

    @media screen and (min-width: 768px) {
        font-size:calc(22rem/16);
        letter-spacing: 3px;
    }
}


/* highlight */
.welcome-teaser--highlight {
    color:#fff;
    background: linear-gradient(0deg, #37BAA4 0%, #239380 100%);
}
.welcome-teaser--highlight .icon {
    opacity: 0.1;
}