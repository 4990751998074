.btn {
    display: inline-block;
    padding:.625rem .875rem;
    font-size:calc(14rem/16);
    letter-spacing: 1px;
    border-radius:5px;
    font-weight: 300;
    white-space: normal;
}
.btn.btn-outline--noborder {
    border:0 !important;
}
.btn.disabled {
    cursor: not-allowed;
}
.btn.btn-sm {
    padding:.625rem .75rem;
    font-size: .75rem;
}
.btn.btn-ticketing-arrivals {
    padding:.9375rem;
}
@media screen and (min-width: 768px) {
    .btn {
        font-size:calc(15rem/16);
        letter-spacing: 2px;
        padding:1rem 1.375rem;
    }
    .btn.btn-lg {
        padding:1rem 3rem;
        font-size: 1.5rem;
    }
}


.btn > .icon{
    position: relative;
    top:.125rem;
    line-height:1px;
    margin-right:.5rem;
    @media screen and (min-width: 768px) {
        top:.125rem;

    }
}
.btn .icon--right{
    margin-right:0;
    margin-left:.5rem;
}
.btn .icon--center{
    margin-right:0;
    margin-left:0;
}


/* primary */
.btn-primary {
    color:#fff;
    background:var(--color-primary);
    border-color:var(--color-primary);
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    color:#fff;
    background:var(--color-primary-dark);
}


/* primary-outline */
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active {
    color:#fff;
    background:var(--color-primary);
}


/* danger */
.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger:active {
    color:#fff;
}


/* default */
.btn-default {
    color:#fff;
    background:var(--color-secondary);
    border-color:var(--color-secondary);
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active {
    color:#fff;
    background:var(--color-default);
}