html {
    /* Table scaling */
    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: calc(16 / (991 / 100) * 1vw);
    }
}
body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size:.875rem;

    @media screen and (min-width: 768px) {
        font-size:1rem;
    }
}
.font-default {
    font-family: var(--font-default);
    font-weight: 300;
}
.small {
    font-size: .75rem;
    @media screen and (min-width: 768px) {
        font-size:.875rem;
    }
}
b,
strong,
.strong {
    font-family: var(--font-default-bold);
    font-weight: 700;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    color: inherit;
    font-weight: 700;
    hyphens: auto;
    font-family: var(--font-default);
}

h1, .h1 {
    font-size: calc(26rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(50rem/16);
    }
}
h2, .h2 {
    font-size: calc(22rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(32rem/16);
    }
}
h3, .h3 {
    font-size: calc(20rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(28rem/16);
    }
}
h4, .h4 {
    font-size: calc(18rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(24rem/16);
    }
}
h5, .h5 {
    font-size: calc(16rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}
h6, .h6 {
    font-size: 1em;
}