.progressbar {
    text-align: center;
    position: relative;
    margin:0 auto 2rem auto;
    width:15rem;

    @media screen and (min-width: 768px) {
        margin-bottom:4rem;
        width:30rem;
    }
}
.progressbar__step--2 .progressbar__circle:before,
.progressbar__step--3 .progressbar__circle:before,
.progressbar__step--4 .progressbar__circle:before,
.progressbar__step--5 .progressbar__circle:before {
    content:'';
    position: absolute;
    top:1.375rem;
    left: -1rem;
    width: 1rem;
    height:1px;
    background: #cccccc;

    @media screen and (min-width: 768px) {
        top:2.25rem;
        left: -2.5rem;
        width: 2.5rem;
        left: -3rem;
        width: 3rem;
    }
}

.progressbar__step {
    width:25%;
    float:left;
    position: relative;
    text-align: center;
    font-size:1.125rem;

    @media screen and (min-width: 768px) {
        font-size:1.875rem;
    }
}
.progressbar__circle {
    display: block;
    position: relative;
    margin:0 auto .5rem auto;
    width:2.75rem;
    height:2.75rem;
    line-height:2.75rem;
    color: #cccccc;
    border-radius: 50%;
    box-shadow: inset 4px 4px 7px 0 #DCDBDB, inset -4px -4px 7px 0 #FFFFFF;
    background: linear-gradient(0deg, #FFFFFF 0%, #E0E0E0 100%);

    @media screen and (min-width: 768px) {
        width:4.5rem;
        height:4.5rem;
        line-height:4.5rem;
    }
}
.progressbar__check {
    display: none;
    position: absolute;
    right:-.25rem;
    bottom:0;
    width:1.25rem;
    height:1.25rem;
    text-align:center;
    background:var(--color-primary);
    border-radius:100%;

    @media screen and (min-width: 768px) {
        right:-.125rem;
    }
}
.progressbar__check .icon {
    color:#fff;
    position: absolute;
    left:50%;
    top:50%;
    transform:translateY(-50%) translateX(-50%);
    font-size:.5rem;
}
/* has data */
.progressbar__step--has-data .progressbar__check {
    display: block;
}



/* is active */
.progressbar__step--active .progressbar__circle {
    background: linear-gradient(0deg, #FFFFFF 0%, #E0E0E0 100%);
    box-shadow: 4px 4px 7px 0 #DBDBDB, -4px -4px 7px 0 #FFFFFF;
    color:#000;
}
.progressbar__step--has-data .progressbar__circle:before,
.progressbar__step--active .progressbar__circle:before {
    background: #000;
}