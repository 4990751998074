.main-footer {
    font-size:calc(15rem/16);
    letter-spacing: .5px;
    margin-top:3rem;

    @media screen and (min-width: 768px){
        margin-top:6rem;
        letter-spacing: 1px;
    }
}

.main-footer a {
    color:inherit;
}
.main-footer a:hover,
.main-footer a:focus {
    color:var(--color-primary);
}

.address-footer {
    padding:1.375rem 1rem;
    text-align: center;
    background-color:#fff;

    @media screen and (min-width: 768px){
        padding:1.625rem 1rem;
    }
}