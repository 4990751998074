.form-errors.invalid-feedback {
    display: block;
}
.form-errors:empty,
.is-valid .form-errors {
    display: none;
}
.form-errors ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    color:var(--color-danger);
}

.form-errors {
    font-size:.875rem;
    position: absolute;
    left:0;
    right:0;
    text-align: center;
    bottom:0;
    pointer-events: none;
}
.radio-group .form-errors,
.form-mail-container .form-errors,
.form-check .form-errors {
    position: relative;
    left: auto;
    right:auto;
    text-align: left;
    bottom:auto;
}
.radio-group .form-errors {
    text-align: center;
}