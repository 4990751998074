.form {
    width: 100%;
    margin:0 auto;
}
.form--narrow {
    max-width: 440px;
}
.form-group {
    position: relative;
    margin-bottom:1.25rem;

    @media screen and (min-width: 768px) {
        margin-bottom:2.25rem;
    }
}

.form-group.form-group--blank {
    margin:0;
    padding:0;
}

.form-group.form-group--busitem {
    box-shadow: 0 10px 20px 0 rgba(144,152,158,0.2);
    border-radius:5px;
    overflow: hidden;

    @media screen and (max-width: 767px) {
        margin:.625rem 0 .75rem 0;
    }
}

.form-control-label {
    font-size:1rem;
    color:var(--color-default);
}

.form-control-label--sm {
    font-size:.875rem;
    @media screen and (min-width: 768px) {
        font-size:.9375rem;
    }
}

.form-control {
    border-radius:5px;
    padding: 1.125rem 1.5rem .875rem 1.5rem;
    border:0;
    height:3.375rem;
    box-shadow: 0 5px 10px 0 rgba(144,152,158,0.33);
    background: linear-gradient(0deg, #FFFFFF 0%, #E0E0E0 100%);
    font-size:1rem;
}

.form-control:focus {
    box-shadow: 0 5px 10px 0 rgba(144,152,158,0.5);
}

textarea.form-control {
    border-radius:5px;
}

.form-control.form-control--blank {
    border-radius:0;
    box-shadow: none;
}
.form-control.has-border-left {
    border-left:1px solid #dadada;
}
.form-control.form-control--sm {
    padding: .75rem 1rem .75rem 1rem;
    height:2.5rem;
    font-size:.875rem;

    @media screen and (min-width: 768px) {
        font-size:1rem;
    }
}
.is-disabled .form-control,
.form-control.is-disabled,
.form-control:disabled,
.form-control[readonly]:not(.hasDatepicker) {
    color: #a8a8a8;
    opacity: 1;
    cursor: not-allowed;
}


.form-mail-container {
    position: relative;
    color:#fff;
    padding:.5rem .5rem 1rem 1.5rem;
    border-radius:5px;
    background:var(--color-primary);

    @media screen and (min-width: 768px) {
        padding:.5rem .5rem .5rem 1.5rem;
    }
}


.datepicker__icon {
    pointer-events: none;
    font-size: 1.25rem;
    position: absolute;
    right:1.75rem;
    top:50%;
    transform: translateY(-50%);
}