.accordion {
    position: relative;
    display: block;
}

.accordion > .card.card {
    margin-bottom:1.25rem;
    border:0;
    border-radius:1rem !important;
    box-shadow: 4px 4px 7px 0 #DBDBDB, -4px -4px 7px 0 #FFFFFF;
}
.accordion > .card > .card-header {
    margin: 0;
    padding:0;
    font-weight:300;
    font-family: var(--font-default);
    background: linear-gradient(0deg, #FFFFFF 0%, #E0E0E0 100%);
    font-size:calc(15rem/16);
    letter-spacing: 1px;
    border-bottom:0;
    z-index:1;

    @media screen and (min-width: 768px) {
        font-size: 1rem;
        letter-spacing: 2px;
    }
}
.accordion .accordion__header-link{
    display: block;
    padding: .75rem 1.25rem;
    @media screen and (min-width: 768px){
        padding: .875rem  1.625rem;
    }
}
.accordion__header-link .accordion__toggle-icon {
    color: var(--color-primary);
}
.card-header > .accordion__header-link:not(.collapsed) {
    color:#fff;
    background: var(--color-primary);
}

.accordion__header-link:not(.collapsed) .accordion__toggle-icon {
    transform: rotate(45deg);
    transition: ease-in 0.1s;
    color: inherit;
}
.accordion .card-body {
    background:#fff;
    padding: .75rem 1.25rem;
    line-height:1.875;
    box-shadow: inset 4px 4px 7px 0 #DCDBDB, inset -4px -4px 7px 0 #FFFFFF, 4px 4px 7px 0 #DBDBDB, -4px -4px 7px 0 #FFFFFF;
    @media screen and (min-width: 768px){
        padding: .875rem  1.625rem 1.125rem  1.625rem;
    }
}
