.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: 2em;
    line-height:1.875;
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.link,
.wysiwyg a {
    color: var(--color-text-default);
    text-decoration: none;
    border-bottom:1px solid;
}
.link:hover,
.link:focus,
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-primary);
}
.modal__sub-hl,
.title-block__pre-title,
.title-block__sub-title,
.title-block__sub-sub-title {
    text-transform: uppercase;
    letter-spacing: 1px;
    @media screen and (min-width: 768px) {
        font-size: calc(17rem/16);
        letter-spacing: 1.5px;
    }
}
.title-block__titles {
    margin-bottom:1rem;
    @media screen and (min-width: 768px){
        margin-bottom:1.5rem;
    }
}
.title-block {
    margin-bottom:1.125rem;
    @media screen and (min-width: 768px){
        margin-bottom:1.5rem;
    }
}


/* lists */
.wysiwyg ul>li {
    margin:.375rem;
    margin-left:1rem;
    padding-left:1rem;
    list-style: none;
    position: relative;
    @media screen and (max-width: 767px) {
        margin-left:0;
    }
}
.wysiwyg ul>li:before {
    content:'';
    position: absolute;
    left:-.75rem;
    top:.625rem;
    display: block;
    width:8px;
    height:8px;
    background: var(--color-primary);
    border-radius: 100%;
}

.wysiwyg ol>li {
    margin:.375rem;
    margin-left:1rem;
    padding-left:1rem;
    @media screen and (max-width: 767px) {
        margin-left:0;
    }
}



.wysiwyg--multicol{
    @media screen and (min-width: 768px){
        column-count: 2;
        column-gap: 4rem;
    }
}