.modal .close.close {
    position: absolute;
    right:0;
    top:0;
    font-size:1.375rem;
    padding:1.25rem;
}


.modal-content {
    padding:3rem .5rem;
    background: linear-gradient(0deg, #FFFFFF 0%, #E0E0E0 100%);
    @media screen and (min-width: 768px) {
        padding:4rem 2.5rem;
    }
}