.wysiwyg table {
    width: 100%;
    max-width: 100%;

}

.wysiwyg table > thead > tr > th,
.wysiwyg table > tbody > tr > th,
.wysiwyg table > tfoot > tr > th,
.wysiwyg table > thead > tr > td,
.wysiwyg table > tbody > tr > td,
.wysiwyg table > tfoot > tr > td {
    padding: .75rem 1.125rem;
    text-align:left;
    background-color: var(--color-white);
    border: 1px solid #e2e5e7;
    @media screen and (min-width: 768px){
        padding: .875rem  1.625rem;
    }
}

.wysiwyg table > thead > tr > th {
    color:#fff;
    border:0;
    background-color: var(--color-text-default);
    font-family: var(--font-default-bold);
}

.wysiwyg table > thead > tr > th:first-child,
.wysiwyg table > tbody > tr > th:first-child,
.wysiwyg table > tfoot > tr > th:first-child,
.wysiwyg table > thead > tr > td:first-child,
.wysiwyg table > tbody > tr > td:first-child,
.wysiwyg table > tfoot > tr > td:first-child {
    border-left: 1px solid var(--color-grey);
}

.wysiwyg table > thead > tr > th:last-child,
.wysiwyg table > tbody > tr > th:last-child,
.wysiwyg table > tfoot > tr > th:last-child,
.wysiwyg table > thead > tr > td:last-child,
.wysiwyg table > tbody > tr > td:last-child,
.wysiwyg table > tfoot > tr > td:last-child{
    border-right: 1px solid var(--color-grey);
}

.wysiwyg table > tbody > tr.has-additional-info > td {
    border:0;
    padding:0;
}

.table-responsive {
    border-radius:1rem;
    box-shadow: 0 5px 10px 0 rgba(144,152,158,0.33);
}

@media screen and (max-width: 767px) {
    .table-responsive {
        min-height: .01%;
        overflow-x: auto;
        width: 100%;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        /*border:2px solid #000;*/
    }
    .table-responsive table {
        border:0;
    }
    .table-responsive > table > thead > tr > th,
    .table-responsive > table > tbody > tr > th,
    .table-responsive > table > tfoot > tr > th,
    .table-responsive > table > thead > tr > td,
    .table-responsive > table > tbody > tr > td,
    .table-responsive > table > tfoot > tr > td {
        white-space: nowrap;
    }
}