.floating-label {
    font-size: .75‬rem;
    position: absolute;
    left: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 200ms ease-in-out;
    transform-origin: left top;
    pointer-events: none;
    white-space: nowrap;
    z-index: 1;
}

.form-control:focus ~ .floating-label,
select.form-control ~ .floating-label,
.form-control.has-value ~ .floating-label,
.form-control.hasDatepicker + .floating-label,
.has-value .floating-label{
    transform: translateY(-1.5rem) translateX(0) scale(.625);
    color:inherit;
    background:transparent;

    @media screen and (min-width: 768px) {
        transform: translateY(-1.5rem) translateX(0) scale(.625);
    }
}


/* gleiche stylings wie oben. verursachtproblem in firefox wenn in selben selektor */
.form-control:-webkit-autofill ~ .floating-label,
.form-control:-webkit-autofill:focus ~ .floating-label{
    transform: translateY(-1.5rem) translateX(0) scale(.625);
    color:inherit;
    background:transparent;

    @media screen and (min-width: 768px) {
        transform: translateY(-1.5rem) translateX(0) scale(.625);
    }
}


select.form-control ~ .floating-label {
    background:#fff;
    transform: translateY(-1.75rem) translateX(.125rem) scale(.75);
    @media screen and (min-width: 768px) {
        transform: translateY(-2.25rem) translateX(-.125rem) scale(.75);
    }
}


/* floating label in textarea */
.text-area .floating-label__label {
    top:1.375rem;

    @media screen and (min-width: 768px) {
        top: 1.75rem;
    }
}