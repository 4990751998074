.navbar-brand {
    width: 187px;
    margin:0;
    padding:0;
    position:absolute;
    right:4rem;
    top:-70px;
    z-index: 50;

    @media screen and (max-width: 991px){
        width: 120px;
        right:1rem;
        top:0;
    }

    @media screen and (min-width: 992px) and (max-width: 1400px){
        top:-40px;
        right:1rem;
        width: 140px;
    }
}


.navbar-nav--right {
    @media screen and (min-width: 992px) and (max-width: 1400px){
        padding-right:10rem;
    }
    @media screen and (min-width: 1401px){
        padding-right:15.875rem;
    }
}


.navbar-brand>img {
    display: block;
    height:auto;
    width: 100%;
}
.navbar-nav .nav-link.nav-link {
    color:#000;
    padding:1.25rem .875rem;
    font-size:calc(15rem/16);

    @media screen and (min-width: 992px) and (max-width: 1400px){
        padding:1.25rem .5rem;
    }

    @media screen and (max-width: 767px){
        letter-spacing:2px;
    }
}
.navbar-nav .nav-link .icon {
    position: relative;
    top:.125rem;
    margin-right:.125rem;
    color:var(--color-primary);

    @media screen and (min-width: 768px) and (max-width: 1400px){
        display:none;
    }
}

.navbar-nav .nav-link .icon-dropdown {
    margin-left:.125rem;
    color:var(--color-primary);
    font-size:.25rem;
    top:-.125rem;
    @media screen and (min-width: 768px) and (max-width: 1400px){
        display:inline-block;
    }
}
.navbar-nav .nav-link.nav-link:hover,
.navbar-nav .nav-link.nav-link:focus,
.navbar-nav .nav-link.nav-link.active {
    color:var(--color-primary);
}




/* mobile */
.xs-ticketing-logout,
.xs-ticketing-back,
.xs-profile,
.xs-login{
    display: none;
}
@media screen and (max-width: 991px){
    .navbar {
        padding:0;
    }
    .navbar-collapse {
        z-index: 5;
        background:#fff;
        box-shadow: 0 0 20px 0 rgba(144,152,158,0.5);
    }
    .navbar-nav {
        padding:0 1rem;
        background:#fff;
        text-align:center;
    }
    .navbar-nav--left {
        margin-top:2.25rem;
    }
    .navbar-nav--right {
        margin-bottom:1rem;
    }
    .navbar-toggler.navbar-toggler {
        border:0;
        color:var(--color-primary);
        display: block;
        padding:1.25rem 1rem 1rem 1.375rem;
        font-size:1.125rem;
        z-index: 15;
    }
    .xs-profile-dropdown {
        position: absolute;
        left:3.75rem;
        top:0;
    }
    .xs-profile,
    .xs-login {
        display: block;
        padding:.875rem 1rem 1rem 1rem;
        font-size:1.25rem;
        z-index: 15;
    }
    .xs-login,
    .xs-ticketing-logout,
    .xs-ticketing-back {
        position: absolute;
        left:3.75rem;
        top:0;
    }
    .xs-ticketing-back,
    .xs-ticketing-logout {
        display: block;
        font-size:.9375rem;
        padding:1.125rem 1rem 1rem 1rem;
        left:.5rem;
        z-index: 15;
    }
    .xs-ticketing-logout {
        left:auto;
        right:9.25rem;
    }
    .xs-ticketing-back .icon,
    .xs-login .icon,
    .xs-profile .icon {
        position: relative;
        top:.125rem;
        margin-left:.25rem;
        color:var(--color-primary);
    }
    .xs-ticketing-back .icon {
        top:0;
        font-size:.75rem;
        margin-left:auto;
        margin-right:.25rem;
    }
}