.alert-notification {
    position: fixed;
    right:1rem;
    bottom:1rem;
    z-index:999;
    width:320px;

    @media screen and (max-width: 767px) {
        width:280px;
    }
}

.alert-notification .alert {
    margin:1rem 0 0 0;
}