.custom-checkbox {
    position: relative;
    padding-left: 1.875em;
}

.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}

.custom-checkbox:after {
    clear: both;
}

.custom-checkbox>label{
    cursor: pointer;
}

.custom-checkbox__input {
    position: absolute;
    width: calc(.875rem/16);
    height: calc(.875rem/16);
    margin: calc(-.875rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.custom-checkbox__box {
    position: absolute;
    left: 0;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: 0;
    border-radius: 3px;
    background: linear-gradient(0deg, #FFFFFF 0%, #E0E0E0 100%);
    box-shadow: inset 4px 4px 7px 0 #DCDBDB, inset -4px -4px 7px 0 #FFFFFF, 4px 4px 7px 0 #DBDBDB, -4px -4px 7px 0 #FFFFFF;
    width: 1.25em;
    height: 1.25em;
}
.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.625em;
    color: var(--color-primary);
    visibility: hidden;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}
.custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-primary);
}
.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}
.custom-checkbox__text {
    display: block;
    overflow: hidden;
    font-size:.875rem;
    /*color: var(--color-text-muted);*/
}
.custom-checkbox__text a {
    color:var(--color-text-default);
    text-decoration: underline;
}
.custom-checkbox__text a:hover,
.custom-checkbox__text a:focus {
    color:var(--color-primary);
}


/* inline */
.form-check-inline .form-check-input {
    position: absolute; /* override bootstrap */
}