.busitem {
    margin:0 0 2.5rem 0;
    padding:0 0 2.5rem 0;
    border-bottom:1px solid #dadada;
    position: relative;
    letter-spacing: 1px;
    font-size:calc(15rem/16);

    @media screen and (min-width: 768px) {
        margin:0 0 3.75rem 0;
        padding:0 0 3.75rem 0;
    }
}
.busitem--single {
    margin:0 0 1.25rem 0;
    padding:0;
    border-bottom:0;
    @media screen and (min-width: 768px) {
        margin:0 0 2.25rem 0;
        padding:0;
    }
}
.busitem.busitem--edit {
    margin:0;
    padding:0;
    border-bottom:0;
}
.busitem__slot,
.busitem__time {
    letter-spacing: 0;
    font-size: calc(14rem/16);
}
.busitem__slot {
    @media screen and (max-width: 767px) {
        margin-bottom:.75rem;
    }
}
.busitem__bus {
    white-space: nowrap;
    font-weight:700;
    font-size: calc(18rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(18rem/16);
    }
}