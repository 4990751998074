/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* Text Color Helper */
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }
.text-muted { color: var(--color-text-muted) !important; }


/* Background Color Helper */



/* Additional */
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.cancellink {
    position: relative;
    font-size:calc(14rem/16);
    color:var(--color-danger);
    font-family: var(--font-default-bold);
    text-decoration: none;
}
.cancellink:hover,
.cancellink:focus {
    color:var(--color-text-default);
}
.cancellink .icon {
    position: relative;
    transform: rotate(45deg);
    top:.125rem;
    margin-right:.25rem;
    text-decoration: none;
}

.backlink {
    text-decoration: none;
}
.backlink:hover,
.backlink:focus {
    color:var(--color-primary);
}
.backlink .icon {
    font-size:.75rem;
    position: relative;
    top:0;
    margin-right:.5rem;
    text-decoration: none;
}

.link-with-icon {
    text-decoration: none;
    color:var(--color-primary);
}
.link-with-icon:hover,
.link-with-icon:focus {
    color:var(--color-primary);
}
.link-with-icon .icon {
    font-size:1.125rem;
    position: relative;
    top:.125rem;
    margin-right:.5rem;
    text-decoration: none;
}
.link-with-icon:not(.collapsed) .icon {
    transform: rotate(45deg);
}

.isCursor {
    cursor: pointer;
}

.increased-click-area {
    padding: calc(8rem/16);
    margin: calc(-8rem/16);
}

.fs1 {
    font-size: 1rem;
}
.fs1-25 {
    font-size: 1.25rem;
}
.fs1-5 {
    font-size: 1.5rem;
}


.image-row {

}
.image-row__item {
    box-shadow: 0 5px 10px 0 rgba(144,152,158,0.33);
}
.content-img {
    box-shadow: 0 5px 10px 0 rgba(144,152,158,0.33);
}