.ui-datepicker {
    z-index: 1500!important;
}
.ui-datepicker .ui-state-disabled {
    opacity: .33;
}
@media screen and (max-width: 767px) {
    .ui-datepicker {
        font-size: 1rem;
    }
}
